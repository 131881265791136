/* global config, angular, moment, PDFJS, Promise, parseInt */

'use strict';

angular.module('managerApp').controller('VenteEnLigneController', (
        $scope, $rootScope, $cookies, $routeParams, $location, $timeout, $mdDialog,
        toast, procedureService, procedureTools, Auth) => {
            
//------------------------------------------------------------------------------

    $scope.config = config;

//------------------------------------------------------------------------------

    const path = $location.path();
    const PROCEDURES_DIR = config.app.proceduresDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    const itemsPerPageCookie = config.app.cookies.itemsPerPage;
    const pendingOnlyProceduresCookie = config.app.cookies.pendingOnlyProcedures;    

//------------------------------------------------------------------------------

    $scope.PROCEDURE = {
        cssClass: 'vel',
        type: 'vente-en-ligne',
        serviceName: 'SERVICE_VENTE_EN_LIGNE',
        listTitle: 'Démarches de vente 100% en ligne (Passcar)'
    };
    $scope.hasTMSPermission = Auth.hasPermission('TMS', 'PUT');
    $scope.hasProcedureEditPermission = Auth.hasPermission($scope.PROCEDURE.serviceName, 'PUT');
    $scope.hasANTSProcedures = false;

//------------------------------------------------------------------------------

    $scope.currentUser = $rootScope.currentUser;
    $scope.messages = procedureTools.messages;
    $scope._type = $scope.PROCEDURE.type;
    $scope.procedureDir = PROCEDURES_DIR + $scope._type + '/';    

//------------------------------------------------------------------------------

    // init
    $scope.currentTab = $routeParams.tab || 0;
    if($scope.currentTab > 4){
        $scope.currentTab = 0;
    }

    $scope.procedureId = $routeParams.id;
    $scope.isItem = typeof $scope.procedureId !== 'undefined';

    // for item
    $scope.procedure = null;
    $scope.client = null;
    $scope.seller = null;
    $scope.buyer = null;
    $scope.coBuyers = [];
    $scope.firstCoBuyer = null;
    $scope.vehicle = null;
    $scope.item = null;

    // for list
    $scope.proceduresCount = null;
    $scope.procedures = [];

    let searchItemsPerPage = $cookies.get('pagination.procedures.itemsPerPage') || 25;
    let maxDate = new Date();
    maxDate.setHours(23, 59, 59);
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: searchItemsPerPage,
        totalItems: 0,
        maxDate: maxDate,
        filters: {},
        pendingOnlyProcedures: $cookies.get(pendingOnlyProceduresCookie) === 'true',
        pageChanged: (loadData) => {
            $cookies.put(pendingOnlyProceduresCookie, $scope.pagination.pendingOnlyProcedures);
            $cookies.put(itemsPerPageCookie, $scope.pagination.itemsPerPage);
            if (loadData === true) {
                getProcedures();
            }
        }
    };

    $rootScope.$on('get-procedures', function (e, type) {
        if (type === $scope._type) {
            $scope.pagination.pendingOnlyProcedures = e.targetScope.search.pendingOnlyProcedures;
            $scope.pagination.filters = e.targetScope.search.filters;
            getProcedures();
        }
    });

    if ($scope.isItem) {
        getProcedure($scope.procedureId);
    } else {
        getProcedures();
    }
    
    $scope.setTab = (index) => {
        
        console.log(window.location.origin)
        
        //if($routeParams.tab){
            window.history.replaceState(null, null, '/procedures/vente-en-ligne/7518721d-ee50-474e-bcb7-dd85adf461b1?tab=2');
        //}
        /*$location.search({
            tab: index
        });*/
    };

    $scope.edit = ($event) => {
        let _this = $event.currentTarget,
                parent = $(_this).parent(),
                item = $(getTargetItem($event));
        let type = $(item).data('type'),
                value = $(item).data('value'),
                field = $(item).data('field');
        $(item).html('');
        $(item).append('<input type="text" name="' + type + '.' + field +
                '" value="' + value + '">');
        $(_this).addClass('hidden');
        $(parent).find($('.btn-validate-edit')).removeClass('hidden');
        $(parent).find($('.btn-cancel-edit')).removeClass('hidden');
        if ($(item).hasClass('badge')) {
            $(item).removeClass('badge');
            $(item).addClass('badge-off');
        }
    };
    $scope.cancelEdit = ($event) => {
        let _this = $event.currentTarget,
                parent = $(_this).parent(),
                item = $(getTargetItem($event));

        let value = $(item).data('value');
        $(item).html(value);

        $(_this).addClass('hidden');
        $(parent).find($('.btn-edit')).removeClass('hidden');
        $(parent).find($('.btn-validate-edit')).addClass('hidden');
        if ($(item).hasClass('badge-off')) {
            $(item).removeClass('badge-off');
            $(item).addClass('badge');
        }
    };
    $scope.validateEdit = ($event, origItem) => {
        let _this = $event.currentTarget,
                parent = $(_this).parent(),
                item = $(getTargetItem($event));
        let type = $(item).data('type'),
                value = $(item).data('value') + '',
                field = $(item).data('field'),
                itemId = $(item).data('data-id'),
                itemType = $(item).data('data-type'),
                itemPersonType = $(item).data('data-person-type'),
                newValue = $(item).find('input').val();
        let data = type === 'person' ? {
            type: itemType,
            personType: itemPersonType
        } : {};
        if (newValue.toLowerCase() !== value.toLowerCase()) {
            if (['true', 'false'].indexOf(newValue) > -1) {
                newValue = newValue === 'true';
            }
            data[field] = newValue;
            procedureService.updateItem($scope.procedureId, type, itemId, data).then(() => {
                toast.show('Les données ont été bien mises à jour.', 'success');
                $(item).html(newValue);
                $(_this).addClass('hidden');
                $(parent).find($('.btn-edit')).removeClass('hidden');
                $(parent).find($('.btn-cancel-edit')).addClass('hidden');
                if ($(item).hasClass('badge-off')) {
                    $(item).removeClass('badge-off');
                    $(item).addClass('badge');
                }
                origItem[field] = newValue;
                $timeout(() => {
                    $scope.$apply();
                });
            }).catch((err) => {
                toast.show(err && err.message ? err.message :
                        'Une 11 erreur est survenue lors du traitement de votre demande.');
            });
        }
    };

    $scope.checkDocument = (document) => {
        if ($scope.procedure.isHandledByUser) {
            if (document.concordance) {
                let msg = 'Vous ne pouvez pas valider ce document car il ' +
                        'est nécessaire de vérifier sa concordance par ' +
                        'rapport ' + document.concordance.documentLabel + ' ',
                        l = msg.length;
                if (document.concordance.document === null) {
                    msg += 'qui n\'a pas encore été téléversé.';
                } else if (document.concordance.document.status !== 'document-accepted') {
                    msg += 'qui n\'a pas encore été validé.';
                }
                if (msg.length > l) {
                    toast.show(msg, 'error', true);
                    return;
                }
            }
            document.validation = procedureTools.getDocValidationQuestions(document);
            procedureTools.openDocumentsViewer(document, $scope);
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }
    };
    $scope.sendToTMS = (procedureId) => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.sendToTMS(procedureId).then((response) => {
                toast.show('La démarche a bien été envoyée à TMS. ' +
                        '<br>' + 'Un e-mail contenant l\'accusé d\'enregistrement à bien été envoyé au client.' +
                        '<br>' + 'Ce document est disponible dans la liste des documents de la démarche. ', 'success', true, 30000);
            }).catch((err) => {
                console.error('error');
                console.error(err);
                toast.show(err && err.message ? err.message :
                        'Une erreur est survenue lors du traitement de votre demande.', 'error', true, 30000);
            });
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }
    };
    $scope.handleUnhandle = (procedureId, unhandle = false) => {
        procedureService.handleUnhandle(procedureId, unhandle).then((response) => {
            toast.show(
                    unhandle ?
                    'La prise en charge de cette démarche a bien été annulée.' :
                    'La démarche a bien été prise en charge. ', 'success', true, 10000);
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', true, 10000);
        });
    };

    $scope.notify = (procedure, type = null) => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.notify(procedure._id, type).then((result) => {
                toast.show('Une notification de relance pour le téléversement des documents ' +
                        'a bien été envoyée au client.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message :
                        'Une erreur est survenue lors du traitement de votre demande.');
                console.error(err);
            });
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }//
    };

    $scope.validate = (procedureId) => {
        if ($scope.procedure.isHandledByUser) {
            procedureService.validate(procedureId).then((result) => {
                toast.show('Cette procédure a bien été validé et' +
                        ' le client a reçu une notification.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message :
                        'Une erreur est survenue lors du traitement de votre demande.', 'error');
            });
        } else {
            toast.show('Vous devez prendre en charge cette démarche' +
                    ' pour pouvoir exécuter cette action.', 'error', true);
        }//
    };

    $scope.getPayments = (procedureId = null, itemId = null) => {
        if ($scope.item.payments.length > 0) {
            return;
        }
        procedureService.getPayments(procedureId, itemId).then((data) => {
            $scope.item.payments = data.NewPayments;
            for (let i = 0; i < $scope.item.payments.length; i++) {
                let payment = $scope.item.payments[i];
                payment.methodText = procedureTools.getProcedurePaymentSupportLabel(payment);
                payment.methodDetails = procedureTools.getProcedurePaymentMethodLabel(payment);
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };

    $scope.getHistory = (procedureId = null) => {
        if ($scope.procedure.history.length > 0) {
            return;
        }
        procedureService.getHistory(procedureId).then((history) => {
            $scope.procedure.history = history;
            for (let i = 0; i < history.length; i++) {
                let historyITem = history[i];
            }
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };

    $scope.getVehicleInfos = (vehicleRegNum = '') => {
        procedureTools.getVehicleInfos(vehicleRegNum).then((infos) => {
            let s = '<div class="no-padding-top padding p20">';
            s += '<h3>' + vehicleRegNum + '</h3><hr>';
            for (let i in infos) {
                let v = infos[i];
                if (v.label !== '') {
                    s += '<p>' + '<span style="display:inline-block;width:260px;font-weight:700">' +
                            v.label + '</span> ' + v.value + '</p>';
                }
            }
            s += '</div>';
            $mdDialog.show({
                controller: function () {},
                template: s,
                parent: angular.element(document.body),
                clickOutsideToClose: true
            });
        }).catch((err) => {
            console.log(err);
            toast.show(err && err.message ? err.message :
                    'Une erreur est survenue lors du traitement de votre demande.', 'error', false, 7500);
        });
    };

    function getProcedure(id) {
        procedureService.get({
            id: id,
            type: $scope._type
        }).then((data) => {

            $scope.procedure = data;
            $scope.client = $scope.procedure.Client;
            $scope.item = $scope.procedure.VenteEnLigne;
            $scope.item.payments = [];
            $scope.procedure.history = [];

            $scope.procedure.isHandled = procedureTools.isHandled($scope.procedure);
            $scope.procedure.isHandledByUser = $scope.procedure.UserId === $rootScope.currentUser._id;
            $scope.procedure.canBeHandledByUser = !$scope.procedure.isHandled &&
                    !$scope.procedure.isHandledByUser;

            $scope.procedure.origin = procedureTools.getOrigin($scope.procedure);

            procedureTools.initProcedurePeople($scope);
            procedureTools.initVehicle($scope);
            procedureTools.initDocuments($scope);

            $scope.$apply();
        });
    }

    function getProcedures() {
        let params = {
            type: $scope._type,
            ants: $scope.isANTS === true ? 1 : 0,
            pendingOnly: $scope.pagination.pendingOnlyProcedures === true ? 1 : 0
        };
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;
            for (let i in filters) {
                if (i === 'date') {
                    params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else if (i === 'DiscountCode') {
                    if (filters.DiscountCode !== null) {
                        params.discountCode = filters.DiscountCode._id;
                    }
                } else {
                    if (filters[i] !== '') {
                        params[i] = filters[i];
                    }
                }
            }
        }
        procedureService.get(params, $scope.pagination.currentPage, $scope.pagination.itemsPerPage).then((data) => {
            $scope.pagination.params = params;
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
                procedure.isHandledByUser = procedure.UserId === $rootScope.currentUser._id;

                let item = procedure.VenteEnLigne;
                procedure.item = item;
                procedure.isSentToTMS = item.status === 'sent-to-tms';
                procedure.completed = procedure.status === 'completed';
                procedure.pending = procedure.status === 'pending';
                procedure.isRecentlyCreated = false;
                procedure.isRecentlyUpdated = false;
                procedure.isReadyForProcessing =
                        procedure.pending &&
                        !procedure.isSentToTMS &&
                        procedure.signed === true &&
                        procedure.documentsUploaded === true &&
                        procedure.waitingForDocuments === false;

                procedure.showWaitingForDocuments = procedure.paid === true &&
                        (procedure.waitingForDocuments === true || procedure.documentsUploaded === false);

                procedure.clientName = (procedure.Client.title + ' ' +
                        procedure.Client.firstname + ' ' + procedure.Client.lastname).toLowerCase();

                procedure.link = '/procedures/' + procedure.type + '/' + procedure._id;

                if (procedure.isReadyForProcessing) {
                    let createdAt = moment(procedure.createdAt),
                            updatedAt = moment(procedure.updatedAt),
                            _3Days = moment().subtract(1, 'days'),
                            hasAtLeast3Days = createdAt.isSameOrBefore(_3Days),
                            updatedRecently = updatedAt.isSameOrAfter(_3Days);
                    if (hasAtLeast3Days && updatedRecently &&
                            procedure.signed === true &&
                            procedure.documentsUploaded === true &&
                            procedure.waitingForDocuments === false) {
                        procedure.isRecentlyUpdated = true;
                    } else {
                        procedure.isRecentlyCreated = true;
                    }
                }
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    function _getProcedures() {
        let params = {
            type: $scope._type
        };
        if ($scope.pagination.filters) {
            let filters = $scope.pagination.filters;
            for (let i in filters) {
                if (i === 'date') {
                    params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else {
                    params[i] = filters[i];
                }
            }
        }
        procedureService.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.proceduresCount = data.count;
            $scope.procedures = data.items;
            for (let i = 0; i < $scope.procedures.length; i++) {
                let procedure = $scope.procedures[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.isHandled = procedureTools.isHandled(procedure);
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

});